import { Link } from '@remix-run/react'

import { Icon } from './ui/icon.tsx'

export function BrandMenu() {
	return (
		<ul className="mt-8 space-y-5">
			<li>
				<Link
					prefetch="intent"
					to="/carros?marca=audi"
					aria-label="Veja os carros da marca Audi"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="audi" className="text-body-2xl">
						Audi
					</Icon>
				</Link>
			</li>
			<li>
				{/*BMW*/}
				<Link
					prefetch="intent"
					to="/carros?marca=bmw"
					aria-label="Veja os carros da marca BMW"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="bmw" className="text-body-2xl">
						BMW
					</Icon>
				</Link>
			</li>
			<li>
				{/*BYD*/}
				<Link
					prefetch="intent"
					to="/carros?marca=byd"
					aria-label="Veja os carros da marca BYD"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="byd" className="text-body-2xl">
						BYD
					</Icon>
				</Link>
			</li>
			<li>
				{/*Chevrolet*/}
				<Link
					prefetch="intent"
					to="/carros?marca=chevrolet"
					aria-label="Veja os carros da marca Chevrolet"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="chevrolet" className="text-body-2xl">
						Chevrolet
					</Icon>
				</Link>
			</li>
			<li>
				{/*Citroes*/}
				<Link
					prefetch="intent"
					to="/carros?marca=citroen"
					aria-label="Veja os carros da marca Citroen"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="citroen" className="text-body-2xl">
						Citroen
					</Icon>
				</Link>
			</li>
			<li>
				{/*Fiat*/}
				<Link
					prefetch="intent"
					to="/carros?marca=fiat"
					aria-label="Veja os carros da marca Fiat"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="fiat" className="text-body-2xl">
						Fiat
					</Icon>
				</Link>
			</li>
			<li>
				{/*Ford*/}
				<Link
					prefetch="intent"
					to="/carros?marca=ford"
					aria-label="Veja os carros da marca Ford"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="ford" className="text-body-2xl">
						Ford
					</Icon>
				</Link>
			</li>
			<li>
				{/*GWM*/}
				<Link
					prefetch="intent"
					to="/carros?marca=gwm"
					aria-label="Veja os carros da marca GWM"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="gwm" className="text-body-2xl">
						GWM
					</Icon>
				</Link>
			</li>
			<li>
				{/*hyundai*/}
				<Link
					prefetch="intent"
					to="/carros?marca=hyundai"
					aria-label="Veja os carros da marca Hyundai"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="hyundai" className="text-body-2xl">
						Hyundai
					</Icon>
				</Link>
			</li>

			<li>
				{/*Jaguar*/}
				<Link
					prefetch="intent"
					to="/carros?marca=jaguar"
					aria-label="Veja os carros da marca Jaguar"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="jaguar" className="text-body-2xl">
						Jaguar
					</Icon>
				</Link>
			</li>
			<li>
				{/*Jeep*/}
				<Link
					prefetch="intent"
					to="/carros?marca=jeep"
					aria-label="Veja os carros da marca Jeep"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="jeep" className="text-body-2xl">
						Jeep
					</Icon>
				</Link>
			</li>
			<li>
				{/*Jeep*/}
				<Link
					prefetch="intent"
					to="/carros?marca=kia%2520motors"
					aria-label="Veja os carros da marca Jeep"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="kia" className="text-body-2xl">
						Kia
					</Icon>
				</Link>
			</li>
			<li>
				{/*Jeep*/}
				<Link
					prefetch="intent"
					to="/carros?marca=land%2520rover"
					aria-label="Veja os carros da marca Land Rover"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="landrover" className="text-body-2xl">
						Land Rover
					</Icon>
				</Link>
			</li>
			<li>
				{/*Mercedez*/}
				<Link
					prefetch="intent"
					to="/carros?marca=mercedes-benz"
					aria-label="Veja os carros da marca Mercedez"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="mercedez" className="text-body-2xl">
						Mercedes-Benz
					</Icon>
				</Link>
			</li>
			<li>
				{/*Nissan*/}
				<Link
					prefetch="intent"
					to="/carros?marca=nissan"
					aria-label="Veja os carros da marca Nissan"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="nissan" className="text-body-2xl">
						Nissan
					</Icon>
				</Link>
			</li>
			<li>
				{/*Peugeot*/}
				<Link
					prefetch="intent"
					to="/carros?marca=peugeot"
					aria-label="Veja os carros da marca Peugeot"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="peugeot" className="text-body-2xl">
						Peugeot
					</Icon>
				</Link>
			</li>
			<li>
				{/*Porsche*/}
				<Link
					prefetch="intent"
					to="/carros?marca=porsche"
					aria-label="Veja os carros da marca Porsche"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="porsche" className="text-body-2xl">
						Porsche
					</Icon>
				</Link>
			</li>
			<li>
				{/*Renault*/}
				<Link
					prefetch="intent"
					to="/carros?marca=renault"
					aria-label="Veja os carros da marca Renault"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="renault" className="text-body-2xl">
						Renault
					</Icon>
				</Link>
			</li>
			<li>
				{/*Tesla*/}
				<Link
					prefetch="intent"
					to="/carros?marca=tesla"
					aria-label="Veja os carros da marca Tesla"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="tesla" className="text-body-2xl">
						Tesla
					</Icon>
				</Link>
			</li>
			<li>
				{/*Volvo*/}
				<Link
					prefetch="intent"
					to="/carros?marca=volvo"
					aria-label="Veja os carros da marca Volvo"
					className="inline-flex items-center gap-2 text-sm font-normal text-muted-foreground transition-all duration-200 hover:translate-x-1 hover:text-primary"
				>
					<Icon name="volvo" className="text-body-2xl">
						Volvo
					</Icon>
				</Link>
			</li>
		</ul>
	)
}
